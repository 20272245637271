<template>
  <modal
    name="membershipUpgradeModal"
    :adaptive="true"
    height="auto"
    class="membership-modal"
    @keydown.esc="handleEsc"
    @before-open="setMembershipType($event.params.type)"
    @closed="upgradeError=false">
    <div class="membership-modal-content fc-padding-10">
      <h1 class="fc-margin-y-0 fc-heading-1 fc-uppercase">{{ copy.heading }}</h1>
      <p>{{ copy.text }}</p>
      <div v-if="upgradeError">
        <p class="fc-text-red fc-bold"> Sorry, an error occured while upgrading. <br>
        Please try again or <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support. 
      </p>
      </div>
      <FCButton
        button-theme="button-primary"
        custom-event-name="nextStep"
        v-track-click="'Confirmation_SubUpgrade_Annual_Complete'"
        @nextStep="upgradeMembership">
        Confirm Upgrade
    </FCButton>
    </div>
  </modal>
</template>

<script>
import { FCButton } from '@fc/angie-ui';
import { MembershipIds } from '@/shared/constants/MembershipIds';

export default {
  name: 'MembershipUpgradeModal',
  components: {
    FCButton,
  },
  data: () => ({
    upgradeError: false,
    type: MembershipIds.ANNUAL,
  }),
  computed: {
    customerId() {
      return this.$store.state.checkout.customerId;
    },
    copy() {
      if (this.type === MembershipIds.SIX_MONTHS) {
        return {
          heading: 'Confirm your upgrade to save 5%',
          text: 'After you receive your equipment, your authorized card on file will be charged $222.30 (plus applicable taxes), lowering your membership from $39/mo to $37.05/mo for 6 months.'
        }
      }

      return {
        heading: 'Confirm your upgrade to save 10%',
        text: 'After you receive your equipment, your authorized card on file will be charged $421.20 (plus applicable taxes), lowering your membership from $39/mo to $35.10/mo.'
      }
    }
  },
  methods: {
    setMembershipType(type) {
      this.type = type;
    },
    handleEsc() {
      this.upgradeError = false;
      this.$modal.hide('membershipUpgradeModal');
    },
    setUpgradeFormCompleted() {
      this.$store.commit('COMPLETE_UPGRADE_FORM');
    },
    markUpgradeSuccessful() {
      this.$store.commit('MARK_UPGRADE_SUCCESSFUL');
    },
    async upgradeMembership() {
      try {
        this.upgradeError = false;
        await this.$api_client.upgradeMembership({customerId: this.customerId, errorEventTracker: this.$analytics.trackError, type: this.type});
        this.$modal.hide('membershipUpgradeModal');
        this.setUpgradeFormCompleted();
        this.markUpgradeSuccessful()
        this.$store.commit('updateCustomerId', null);
        this.$analytics.trackEvent('Upgrade Membership Success');
      } catch (err) {
        this.upgradeError = true;
        this.$analytics.trackEvent('Upgrade Membership Error', { error: err });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.membership-modal {
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &-title {
    font-size: 1.5rem;
    text-align: center;
    color: $color-blue;

    @media #{$tablet-up} {
      font-size: 2rem;
    }
  }

  &-text {
    font-size: 1.125rem;
    padding: 0 2rem 1rem;
    margin-top: 0;
    text-align: center;
  }
}
</style>
